<template>
  <v-card>
    <v-card-text>
      <v-toolbar class="mb-4">
        <v-text-field v-model="nameFilter" label="User Search" outlined dense clearable hide-details style="max-width:250px"></v-text-field>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ accessFilterText }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="{ text, value } in accessFilterOptions" :key="'level-' + value" :disabled="accessFilter === value" @click="accessFilter = value">
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="dense">
          <v-btn :value="true">Dense View</v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense dark style="background-color: #193264">
              <v-toolbar-title>Board Users</v-toolbar-title>
            </v-toolbar>
            <v-list :dense="dense">
              <v-list-item v-for="({ _id, name, email, college, foundation }, index) in filteredBoardUsers" :key="_id" style="border-bottom:1px solid #CCC">
                <v-list-item-content>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="!dense">{{ email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="foundation" style="margin:0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" :small="dense" label outlined>F</v-chip>
                    </template>
                    <span>Foundation Board Member</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action v-if="college" style="margin:0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" :small="dense" label outlined>C</v-chip>
                    </template>
                    <span>College Board Member</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action style="margin:0">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :small="dense" icon>
                        <v-icon>fal fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="editBoardUser(_id, index)">
                        <v-list-item-title>Edit Board User</v-list-item-title>
                      </v-list-item>
                      <v-list-item :disabled="!collegeAccess" @click="updateBoardUser(_id, index, { college: !college })">
                        <v-list-item-title>{{ college ? 'Revoke' : 'Grant' }} Access to College Board</v-list-item-title>
                      </v-list-item>
                      <v-list-item :disabled="!foundationAccess" @click="updateBoardUser(_id, index, { foundation: !foundation })">
                        <v-list-item-title>{{ foundation ? 'Revoke' : 'Grant' }} Access to Foundation Board</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeBoardUser(_id, index)">
                        <v-list-item-title>Remove Board User</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-alert v-if="boardUsers.length === 0" type="info" outlined>
                List is Empty. Click the button below to add a board member login
              </v-alert>
              <v-alert v-else-if="filteredBoardUsers.length === 0">
                There are no entries that match the current filter.
              </v-alert>
            </v-list>
            <v-card-actions>
              <v-dialog v-model="addNewBoardUser" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="success">
                    <v-icon left>fal fa-plus</v-icon>
                    Add User
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Add a new Board Member</v-card-title>
                  <v-card-text>
                    <v-form ref="newBoardUserForm">
                      <v-text-field v-model="newBoardUserName" :rules="nameRules" label="Name" outlined></v-text-field>
                      <v-text-field v-model="newBoardUserEmail" :rules="emailRules" label="Email" outlined></v-text-field>
                      <v-text-field v-model="newBoardUserPassword" :rules="passwordRules" label="Initial Password" outlined></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="addNewBoardUser = false">Cancel</v-btn>
                    <v-btn text color="success" @click="addBoardUser">Add User</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-btn color="success" outlined>
                <v-icon left>fal fa-envelope</v-icon>
                Email Board Users
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-toolbar dense dark style="background-color: #193264">
              <v-toolbar-title>Covenant Users</v-toolbar-title>
            </v-toolbar>
            <v-list :dense="dense">
              <v-list-item v-for="({ _id, name, email, college, foundation }, index) in filteredLdapUsers" :key="'ldap-' + index" style="border-bottom:1px solid #CCC">
                <v-list-item-content>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="!dense">{{ email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="foundation" style="margin:0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" label outlined>F</v-chip>
                    </template>
                    <span>Access to Foundation Board materials</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action v-if="college" style="margin:0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" label outlined>C</v-chip>
                    </template>
                    <span>Access to College Board materials</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action style="margin:0">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon small>fal fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item :disabled="!collegeAccess" @click="updateLdapUser(_id, index, { college: !college })">
                        <v-list-item-title>{{ college ? 'Revoke' : 'Grant' }} Access to College Board</v-list-item-title>
                      </v-list-item>
                      <v-list-item :disabled="!foundationAccess" @click="updateLdapUser(_id, index, { foundation: !foundation })">
                        <v-list-item-title>{{ foundation ? 'Revoke' : 'Grant' }} Access to Foundation Board</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeLdapUser(_id, index)">
                        <v-list-item-title>Remove User</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-alert v-if="ldapUsers.length === 0" type="info" outlined>
                List is Empty. Click the button below to add a board member login
              </v-alert>
              <v-alert v-else-if="filteredLdapUsers.length === 0">
                There are no entries that match the current filter.
              </v-alert>
            </v-list>
            <v-card-actions>
              <v-dialog v-model="addNewLdapUser" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="success">
                    <v-icon left>fal fa-plus</v-icon>
                    Add User
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Add Access for Covenant User</v-card-title>
                  <v-card-text>
                    <v-form ref="formRef">
                      <employee-search v-model="ldapUser" search-label="Employee Search" :person-filter="['Staff', 'Faculty']" clearable></employee-search>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="addNewLdapUser = false; ldapUser = null">Cancel</v-btn>
                    <v-btn text color="success" @click="addLdapUser">Add User</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-btn color="success" outlined>
                <v-icon left>fal fa-envelope</v-icon>
                Email Covenant Users
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="boardLoginEdit" width="500">
      <v-card>
        <v-card-title>Edit Board Member Login</v-card-title>
        <v-card-text>
          <v-form ref="newBoardUserForm">
            <v-text-field v-model="editBoardUserName" :rules="nameRules" label="Name" outlined></v-text-field>
            <v-text-field v-model="editBoardUserEmail" :rules="emailRules" label="Email" outlined></v-text-field>
            <v-text-field v-model="editBoardUserPassword" :rules="editPasswordRules" label="Create New Password" hint="Leave blank to leave password unchanged" outlined persistent-hint></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="boardLoginEdit = false">Cancel</v-btn>
          <v-btn text color="success" @click="saveBoardLoginEdits">Update User</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
export default {
  components: {
    EmployeeSearch: () => import('@/components/greatscots/searchField')
  },
  props: {
    collegeAccess: {
      type: Boolean,
      default: false
    },
    foundationAccess: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const userService = root.$feathers.service('board/users')

    const nameFilter = ref('')
    const accessFilter = ref(null)
    const accessFilterOptions = ref([
      { text: 'All Active Users', value: null },
      { text: 'Access to College Board', value: 'C' },
      { text: 'Access to Foundation Board', value: 'F' },
      { text: 'Access to Both College and Foundation Board', value: 'B' }
    ])
    const accessFilterText = computed(() => accessFilterOptions.value.filter(({ value }) => value === accessFilter.value)[0].text)
    const dense = ref(false)
    const boardUsers = ref([])
    const filteredBoardUsers = computed(() => {
      let filtered = boardUsers.value.filter(({ college, foundation }) => {
        if (accessFilter.value == null) return true
        if (accessFilter.value === 'C') return college
        if (accessFilter.value === 'F') return foundation
        if (accessFilter.value === 'B') return college && foundation
      })
      if (nameFilter.value != null && nameFilter.value !== '') {
        const regex = new RegExp(nameFilter.value, 'ig')
        filtered = filtered.filter(({ name, email }) => regex.test(name) || regex.test(email))
      }
      return filtered
    })
    const addNewBoardUser = ref(false)

    const newBoardUserForm = ref(null)
    const newBoardUserName = ref('')
    const nameRules = ref([
      (v) => !!v || 'Required'
    ])
    const newBoardUserEmail = ref('')
    const emailRules = ref([
      (v) => !!v || 'Required',
      (v) => /[\w\d._%+-]+@[\w\d.-]+\.[\w]{2,4}/i.test(v) || 'Invalid E-mail address'
    ])
    const newBoardUserPassword = ref('')
    const showPassword = ref(false)
    const passwordRules = ref([
      (v) => !!v || 'Required',
      (v) => (!!v && v.length >= 8) || 'Must be at least 8 characters',
      (v) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*\-.,'";:<>_=+()]).{8,}$/.test(v) || 'Requires upper, lower, and number or symbol'
    ])
    const editPasswordRules = ref([
      (v) => (v == null || v === '') || (v.length >= 8) || 'Must be at least 8 characters',
      (v) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*\-.,'";:<>_=+()]).{8,}$/.test(v) || 'Requires upper, lower, and number or symbol'
    ])

    async function addBoardUser () {
      if (newBoardUserForm.value.validate()) {
        const row = await addUser({
          email: newBoardUserEmail.value,
          password: newBoardUserPassword.value,
          name: newBoardUserName.value
        })
        if (row != null) {
          boardUsers.value.push(row)
          newBoardUserForm.value.reset()
          addNewBoardUser.value = false
        }
      }
    }
    async function updateBoardUser (id, index, patch) {
      const obj = await userService.patch(id, patch)
      boardUsers.value.splice(index, 1, obj)
    }
    async function removeBoardUser (id, index) {
      const { college, foundation } = boardUsers.value[index]
      if (props.foundationAccess && !props.collegeAccess && college) {
        await updateBoardUser(id, index, { patch: { foundation: false } })
      } else if (props.collegeAccess && !props.foundationAccess && foundation) {
        await updateBoardUser(id, index, { patch: { college: false } })
      } else if (props.collegeAccess && props.foundationAccess) {
        await updateBoardUser(id, index, { patch: { college: false, foundation: false, active: false } })
      }
    }
    const boardLoginEdit = ref(false)
    const editBoardLoginId = ref('')
    const editBoardLoginIndex = ref(null)
    const editBoardUserEmail = ref('')
    const editBoardUserName = ref('')
    const editBoardCollege = ref(false)
    const editBoardFoundation = ref(false)
    const editBoardUserPassword = ref('')
    function editBoardUser (_id, index) {
      editBoardLoginId.value = _id
      editBoardLoginIndex.value = index
      const { name, email, college, foundation } = boardUsers.value[index]
      editBoardUserEmail.value = email
      editBoardUserName.value = name
      editBoardCollege.value = college
      editBoardFoundation.value = foundation
      editBoardUserPassword.value = ''
      boardLoginEdit.value = true
    }
    function saveBoardLoginEdits () {
      let obj = {}
      let changed = false
      if (editBoardUserEmail.value !== boardUsers.value[editBoardLoginIndex.value].email) {
        obj.email = editBoardUserEmail.value
        changed = true
      }
      if (editBoardUserName.value !== boardUsers.value[editBoardLoginIndex.value].name) {
        obj.name = editBoardUserName.value
        changed = true
      }
      if (editBoardCollege.value !== boardUsers.value[editBoardLoginIndex.value].college) {
        obj.college = editBoardCollege.value
        changed = true
      }
      if (editBoardFoundation.value !== boardUsers.value[editBoardLoginIndex.value].foundation) {
        obj.foundation = editBoardFoundation.value
        changed = true
      }
      if (editBoardUserPassword.value !== '') {
        obj.password = editBoardUserPassword.value
        changed = true
      }
      if (changed) {
        userService.patch(editBoardLoginId.value, obj).catch((e) => {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'Error updating board login: ' + e })
        }).then((data) => {
          boardUsers.value.splice(editBoardLoginIndex.value, 1, data)
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Board login updated' })
          boardLoginEdit.value = false
        })
      } else {
        alert('No changed data to save.')
      }
    }

    const ldapUsers = ref([])
    const filteredLdapUsers = computed(() => {
      let filtered = ldapUsers.value.filter(({ college, foundation }) => {
        if (accessFilter.value == null) return true
        if (accessFilter.value === 'C') return college
        if (accessFilter.value === 'F') return foundation
        if (accessFilter.value === 'B') return college && foundation
      })
      if (nameFilter.value != null && nameFilter.value !== '') {
        const regex = new RegExp(nameFilter.value, 'ig')
        filtered = filtered.filter(({ name, email }) => regex.test(name) || regex.test(email))
      }
      return filtered
    })
    const addNewLdapUser = ref(false)

    const ldapUser = ref('')
    const ldapSearch = ref('')
    const ldapSearchResults = ref([])
    watch(ldapSearch, (val) => {
      if (val == null || val === '') ldapSearchResults.value = []
      else {
        userService.find({ query: { name: { $regex: val, $options: 'i' }, password: { $exists: false }, $limit: 10, $sort: { name: 1 } } }).then(({ data }) => {
          ldapSearchResults.value = data
        })
      }
    })
    async function addLdapUser () {
      const dirRow = await root.$feathers.service('directory/people').get(ldapUser.value.value)
      const row = await addUser({ username: dirRow.ldap, name: dirRow.name.first + ' ' + dirRow.name.last, email: dirRow.email })
      if (row != null) {
        ldapUsers.value.push(row)
        addNewLdapUser.value = false
        ldapUser.value = null
      }
    }
    async function updateLdapUser (id, index, patch) {
      const obj = await userService.patch(id, patch)
      if (obj.active === false) ldapUsers.value.splice(index, 1)
      else ldapUsers.value.splice(index, 1, obj)
    }
    async function removeLdapUser (id, index) {
      const { college, foundation } = ldapUsers.value[index]
      if (props.foundationAccess && !props.collegeAccess && college) {
        await updateLdapUser(id, index, { foundation: false })
      } else if (props.collegeAccess && !props.foundationAccess && foundation) {
        await updateLdapUser(id, index, { college: false })
      } else if (props.collegeAccess && props.foundationAccess) {
        await updateLdapUser(id, index, { college: false, foundation: false, active: false })
      } else if (!props.collegeAccess && !props.foundationAccess) {
        await updateLdapUser(id, index, { active: false })
      }
    }

    async function addUser ({ username, email, name, password }) {
      const query = {}
      if (username) query.username = username
      else query.email = email
      const { data: userData } = await userService.find({ query })
      if (userData.length === 0) {
        const row = await userService.create({ username, email, name, password })
        return row
      } else if (userData.length === 1) {
        const row = await userService.patch(userData[0]._id, { active: true, password })
        return row
      } else {
        alert('Multiple users exist!!! Contact Tech Services to correct this problem.')
      }
    }

    onMounted(() => {
      userService.find({ query: { active: true, username: { $exists: false }, $limit: 50, $sort: { name: 1 } } }).then(({ data }) => { boardUsers.value = data })
      userService.find({ query: { active: true, username: { $exists: true }, $limit: 50, $sort: { name: 1 } } }).then(({ data }) => { ldapUsers.value = data })
    })

    return {
      nameFilter,
      accessFilter,
      accessFilterOptions,
      accessFilterText,
      dense,
      boardUsers,
      filteredBoardUsers,
      addNewBoardUser,
      ldapUsers,
      filteredLdapUsers,
      addNewLdapUser,
      newBoardUserForm,
      newBoardUserName,
      nameRules,
      newBoardUserEmail,
      emailRules,
      newBoardUserPassword,
      showPassword,
      passwordRules,
      editPasswordRules,
      addBoardUser,
      updateBoardUser,
      removeBoardUser,
      boardLoginEdit,
      editBoardUserEmail,
      editBoardUserName,
      editBoardCollege,
      editBoardFoundation,
      editBoardUserPassword,
      editBoardUser,
      saveBoardLoginEdits,
      ldapUser,
      ldapSearch,
      ldapSearchResults,
      addLdapUser,
      updateLdapUser,
      removeLdapUser
    }
  }
}
</script>
